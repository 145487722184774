.exporter-main {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;
}

.row {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 80%;
}

.exporter-mod {
    width: 1000px;
}

.price-cell {
    width: 5em;
    font-size: 1rem !important;
}

.smooth-visible {
    height: 0%;
    transition: height 0.25s linear;
}

.smooth-visible.active {
    height: 100%
}

.exporterNav {
    display: flex;
    flex-flow: column;
    background-color: white;
    transition: .5s ease;
    justify-items: flex-start;
    border-right: 3px solid #90a4ae;
    margin-right: 3em;
    height: fit-content;
    padding-left: 12px;
    padding-right: 12px;
}

.exporterNav-item {
    color: #253053;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    text-decoration: none;
    text-align: left;
    align-content: flex-start;
    margin: auto;
    min-width: 150px;
    width: fit-content;
    height: fit-content;
}

    .exporterNav-item:hover {
        background-color: #e0e0e0;
        border-color: rgb(189 189 200);
        color: black;
        font-weight: 300;
        transition-duration: 0.2s;
    }

.export-nav-menu {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.drop {
    padding-left: 5px;
    background-color: #e0e0e0;
}

@media screen and (max-width: 900px) {
    .exporterNav-item {
        color: #253053;
        font-family: "Open Sans", sans-serif;
        align-content: center;
        min-width: 90px;
        height: 100%;
        font-size: initial;
    }

    .exporterNav-item-drop {
        color: #253053;
        font-size: initial;
        text-decoration: none;
        text-align: center;
        align-content: center;
        margin: auto;
        font-family: "Open Sans", sans-serif;
        align-content: center;
        text-align: center;
        min-width: 90px;
        min-height: 2.5rem;
    }

        .exporterNav-item-drop:hover {
            background-color: whitesmoke;
            border-color: rgb(189 189 200);
            color: black;
            padding-top: 0px;
            transition-duration: 0.2s;
        }

    .exporterNav-item:hover {
        background-color: #e0e0e0;
        border-color: rgb(189 189 200);
        color: black;
        padding-top: 0px;
        transition-duration: 0.2s;
    }
}
