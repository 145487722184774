.topnav {
    width: 100%;
    background-color: white;
    transition: .5s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-bottom: 3px solid #90a4ae;
}

.wrap-height {
    height:auto!important;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.sidenav-item {
    color: #253053;
    border-right: 1px solid rgb(189 189 200);
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
    align-content: center;
    margin: auto;
    min-width: 150px;
    width: fit-content;
}

    .sidenav-item:last-child {
        border-right: 0px solid rgb(189 189 200);
    }

.sidenav-item:hover {
    background-color: #e0e0e0;
    border-color: rgb(189 189 200);
    color: black;
    height: 100%;
    padding-top: 20px;
    transition-duration: 0.2s;
}

.det-view-min {
    min-width: 700px;
}

.app-container {
    display: flex;
    flex-direction: column;
}

.main-display {
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: space-evenly;
}

.searchbar-container {
    width:150px;
}

.flex-row-single-element {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.flex-row-single-element-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.flex-row, .topnav-desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px;
    border: none;
    justify-content: space-around;
}
.flex-row-special {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px;
    border: none !important;
    justify-content: space-around;
   
}

.flex-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

/* Dise�o segun estado de reservaci�n */
.r-state-1 {
    background-color: lightgoldenrodyellow
}

.r-state-2 {
    background-color: lightblue
}

.r-state-3 {
    background-color: lightgreen
}

.input-min-width {
    min-width: 250px
}

.card {
    background-color: #fafafa;
    text-align: center;
    border: 1px solid #90a4ae;
    border-radius: 5px;
    height: 16em;
    width: 12em;
}

.card-large {
    background-color: whitesmoke;
    text-align: center;
    border: solid;
    border-radius: 5px;
    height: 6em;
    width: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.alert-text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #212121;
}

.button-green {
    background-color: #00986a;
    border: none;
}

.button-green-action {
    background-color: #00986a;
    border: none;
    font-family:'Open Sans';
    font-size:small;    
}

.button-blue {
    background-color: #1976d2;
    border: none;
    font-family: 'Open Sans';
    font-size: small;
}

.btn-red {
    background-color: #d32f2f;
    border: none;
    font-family: 'Open Sans';
    font-size: small;
}

.btn-gray {
    background-color: #757575;
    border: none;
    font-family: 'Open Sans';
    font-size: small;
}
.btn-yw {
    background-color: #ffd600;
    border: none;
    font-family: 'Open Sans';
    font-size: small;
}
.alert-warning {
    border-color: #e6e6e6;
    border-left: 5px solid #f9af2c;
    background-color: white;
    color: #424242;
}

.form-control-select {
    border: none;
    outline: none;
    
}

.alert-info {
    border-color: #e6e6e6;
    border-left: 5px solid #00b3c8;
    background-color: white;
    color: #424242;
}

.alert-success {
    border-color: #e6e6e6;
    border-left: 5px solid #00986a;
    background-color: white;
    color: #424242;
}

.alert-danger {
    border-color: #e6e6e6;
    border-left: 5px solid #c82630;
    background-color: white;
    color: #424242;
}

.div-ver-reserva {
    border-top: 1px solid #90a4ae;
   
}
div-ver-reserva-b {
    border-top: 1px solid ;
}

.list-g {
    border: none;
    border-top: none !important;
    border-block: 2px solid #eeeeee;
}

.table-bg {
    background-color: none !important;
}

.td-especial{
   width:120px;
}

.form-group-compact {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%
}

@media print {
    body {
        margin: 0;
        color: #000;
        background-color: #fff;
    }

    .topnav {
        display: none;
    }

    .main-display {
        margin-top: 3rem;
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 100%;
        justify-content: space-evenly;
    }

    .btn-hide {
        display: none;
    }

    .head-print {
        margin-bottom: 30px;
    }

    .table-print {
        border: none !important;
        margin-top: 30px;
        align-items: center;
    }
}

.topnav-dropdown {
    display: none;
}

.nav-logo {
    width:76px;
}
/* Mobile */
@media screen and (max-width: 900px) {
    .main-display {
        margin-top: 3rem;
        margin-left: 0px;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
    }

    .fa-3x {
        font-size: 2em;
    }

    .alert-info{
        border-color: #e6e6e6;
        border-left: 10px solid #00b3c8;
        background-color: white;
        color: #424242;
    }

    .topnav {
        height: 3rem;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: white;
        transition: .5s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 3px solid #90a4ae;
    }

    .topnav-dropdown {
        display: flex;
        flex-direction: row;
        border: none;
        justify-content: space-between;
    }

    .topnav-desktop {
        display: none;
    }

    .right-side-pres {
        display: none!important;
    }

    .sidenav-item {
        color: #253053;
        border-right: 1px solid rgb(189 189 200);
        font-family: "Open Sans", sans-serif;
        align-content: center;
        min-width: 90px;
        height: 100%;
        font-size: initial;
    }

    .sidenav-item-drop {
        color: #253053;
        font-size: initial;
        text-decoration: none;
        text-align: center;
        align-content: center;
        margin: auto;
        font-family: "Open Sans", sans-serif;
        align-content: center;
        text-align: center;
        min-width: 90px;
        min-height: 2.5rem;
    }

    .sidenav-item-drop:hover {
        background-color: whitesmoke;
        border-color: rgb(189 189 200);
        color: black;
        height: 100%;
        padding-top: 0px;
        transition-duration: 0.2s;
    }

    .sidenav-item:hover {
        background-color: #e0e0e0;
        border-color: rgb(189 189 200);
        color: black;
        height: 100%;
        padding-top: 0px;
        transition-duration: 0.2s;
    }

    .nav-drop {
        width: 200px;
        height: 100px;
        background: white;
    }

    .nav-menu {
        height: fit-content;
        display: flex;
        flex-direction: column;
        width: 500px;
        margin-top: 3rem;
    }

    .nav-drop-enter.nav-drop-enter-active {
        height: 100px;
        -webkit-transition: height .3s ease;
    }

    .nav-drop-leave.nav-drop-leave-active {
        height: 0px;
        -webkit-transition: height .3s ease;
    }
}